export const contentTableConfig = {
  // title: '('param.app-application-list')',
  propList: [
    {
      prop: 'name',
      label: 'monitor.name',
      width: '150'
    },
    {
      prop: 'versionCode',
      label: 'monitor.version-code'
    },
    {
      prop: 'versionName',
      label: 'monitor.version-name'
    },
    {
      prop: 'packageName',
      label: 'monitor.package-name'
    },

    {
      prop: 'updatedTime',
      slotName: 'formatZeroTime',
      label: 'monitor.last-report-time'
    }
  ],
  showIndexColumn: true,
  hidePagination: true
  // showSelectColumn: true
}
